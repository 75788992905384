main {
  .archive-header {
    padding-top: 5rem;

    h2 {
      font-size: 4.5rem;
      font-weight: 900;
      text-transform: capitalize;
    }

    .breadcrumb {
      display: inline-block;
      text-transform: capitalize;
      color: #6e6e6e;
      font-size: 1.4rem;
      font-weight: 500;

      .nav-icon {
        margin: 0 1rem;
      }
    }

    .horizontal-line {
      margin: 3rem 0 5rem 0;
      border-color: rgb(225, 225, 225);
    }
  }

  .article-list-section {
    padding-bottom: 5rem;
  }

  .article-list-sectioon {
    article {
      &:hover {
        transform: translateY(-5px);
      }

      .row {
        margin-bottom: 4rem;

        .img {
          min-height: 150px;
          border-radius: 5px;
        }

        .col-md-8 {
          align-self: center;

          .post-title {
            font-size: 2rem;
            font-weight: 900;
            margin-bottom: 5rem;
          }

          .post-date-read-like {
            font-size: 1rem;

            span {
              font-size: 1.2rem;
            }

            .has-dot::before {
              left: 5px;
            }
          }
        }
      }
    }
  }
}
