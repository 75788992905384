// security1
.header-bg-color {
  background-color: #4991e4;
}
.payload-bg-color {
  background-color: #f6c212;
}
.signature-bg-color {
  background-color: #d3d3d3;
}
