@import "../../../assets/scss/variable.scss";

main {
  .author-block {
    font-size: 1.2rem;
    line-height: 1;
    color: #666;

    .author-avatar {
      float: left;
      margin: -0.3rem 1.5rem 0 0;

      img {
        height: 4rem;
        width: 4rem;
        border: 2px solid #fff;
        border-radius: 50%;
      }
    }

    .author-name {
      font-weight: 700;
    }
  }

  .article-cover-img {
    margin-bottom: 3rem !important;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }
  }

  .complete-reading-block {
    .complete-date {
      margin-right: 0.6rem;
    }

    .has-dot {
      padding-left: 1.2rem;
    }
  }

  .entry-title {
    margin-bottom: 3rem !important;
    font-weight: 900;
    font-size: 3.5rem;
  }

  .share-block {
    margin-right: 1.5rem;

    > li > span {
      color: $color-muted;
    }
  }

  .has-dot::before {
    left: 1px;
  }

  pre {
    margin: 2rem 0 2rem 0 !important;
  }

  // 文章
  article {
    margin-bottom: 5rem;

    h2 {
      line-height: 1.25;
      margin: 5rem 0 1.6rem 0;
      font-size: 2.4rem;
      font-weight: 600;
      color: #242424;
    }

    h5 {
      margin-bottom: 1rem;
    }

    p {
      color: #242424;
      font-size: 1.1em;
      line-height: 1.85;
      margin-bottom: 1rem;

      a {
        color: $color-primary;
      }
    }

    img {
      margin: 0rem 0 5rem 0;
    }

    ul {
      li {
        position: relative;
        margin-bottom: 2rem;
        list-style-type: disc;
        margin-left: 2rem;
        font-size: 1.8rem;
      }
    }

    blockquote {
      margin: 30px 0px;

      p {
        color: #4e4e4e;
        font-size: 1.6rem;
        font-style: italic;
        line-height: 1.5em;
        padding-left: 2rem;
        border-left: 4px solid #cacaca;
      }
    }

    .main-content {
      p {
        font-size: 1.8rem;
      }

      .code-highlight {
        color: #c7254e;
        background-color: #f9f2f4;
        border-radius: 4px;
        padding: 2px 4px;
      }
    }

    .references {
      div {
        display: flex;
        a,
        span {
          font-size: 1.8rem;
        }
        a {
          color: $color-primary;
        }
        span {
          color: #242424;
          margin-left: 1rem;
        }
        &:not(:last-child) {
          display: block;
          margin-bottom: 1.5rem;
        }
      }
    }

    .article-tags {
      margin-top: 5rem;
      a:not(:last-child) {
        margin-right: 1rem;
      }
    }

    .number-circle {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid;
      text-align: center;
      font-weight: bold;
      margin: 2.4rem 0 0.6rem 0;
    }

    .bg-highlight-grey {
      background-color: #d3d3d3;
    }
  }

  // Share Block
  .header-social-network {
    line-height: 1;
  }
  .header-social-network .list-inline-item a {
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 30px;
    line-height: 24px;
    text-align: center;
    font-size: 11px;
    color: $color-white !important;
  }
  .header-social-network .list-inline-item:not(:last-child) {
    margin-right: 7px;
  }

  .comment-borard-block {
    margin-top: 3rem;
  }

  // ==========文章特有的==========

  // Network1、2
  .number-title {
    display: flex;
    margin-bottom: 2rem;
    p {
      &:first-child {
        color: $color-white;
        background: #003d79;
        border-radius: 0.2rem;
        padding: 0.5rem 2rem;
        margin: 0 10px 0 0;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      }

      &:last-child {
        font-size: 2rem;
      }
    }
  }

  // Network2
  .bg-light-green {
    background-color: #9de58a;
  }

  .bg-light-orange {
    background-color: #eb9c72;
  }

  // Network3
  .network3-color-orange {
    color: #f35b0a !important;
  }

  .network3-color-green {
    color: #449d13 !important;
  }

  .network3-color-red {
    color: #f50707 !important;
  }

  .network3-color-yellow {
    color: #e1aa1f !important;
  }

  .packet-info {
    img {
      margin-top: 20px !important;
    }
  }

  // Network5
  .network5-bg-grey {
    background-color: #d3d3d3 !important;
  }
}
