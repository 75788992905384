@import "../../assets/scss/variable.scss";

header {
  font-weight: 600;

  .header-content {
    font-size: 1.6rem;

    margin: 1rem 0;

    .logo {
      max-width: 100%;
    }

    .navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      ul {
        margin: 0;
      }

      .navigation-icon {
        font-size: 2rem;
        color: $color-link;
        margin-right: 0.5rem;
      }

      .vertical-icon {
        font-size: 3.5rem;
        color: $color-link;
        border-width: 1px;
        margin: 0 1.2rem;
      }

      .search-icon {
        margin-right: 2px;
      }

      .timeline-button {
        border: none;
        background-color: transparent;

        .timeline-icon {
          font-size: 3rem;
        }
      }

      .reading-container {
        margin-left: 20px;
      }
    }

    .list-inline {
      li {
        position: relative;
        padding: 0 0.6rem;
      }

      .list-inline-item {
        > li > a {
          padding: 15px 0;
          display: block;
          position: relative;
          font-size: 1.6rem;
        }
        > a > i {
          margin-right: 0.5rem;
        }
      }

      .menu-item-has-children {
        .icon-container {
          position: relative;
          .dropdown-icon {
            font-size: 2rem;
            position: absolute;
            right: -1.8rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &:hover {
          .sub-menu {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }
    }

    .sub-menu {
      position: absolute;
      min-width: 14rem;
      background: $color-white;
      left: 0;
      top: 130%;
      visibility: hidden;
      opacity: 0;
      padding: 1.5rem 0;
      padding-left: 1.5rem;
      transition: all 0.2s ease-out 0s;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
      z-index: 9999;
      border: 1px solid #fafafa;
      border-radius: 3px;

      a {
        padding: 15px 0;
        display: block;
      }
    }

    .search-btn {
      border: none;
      background: none;
      font-weight: 500;
      padding: 0;
      span {
        margin-right: 1.6rem;
        color: $color-link;

        i {
          margin-right: 0.5rem;
        }
      }
    }

    .about-btn {
      font-size: 1.3rem;
      margin-left: 1.5rem;
      background-color: $color-primary;
      color: $color-white;
      font-weight: 700;
      font-style: normal;
      padding: 0.6rem 1rem;
      border-radius: 4px;
    }
  }
}
