@import "../../assets/scss/variable.scss";

.timeline {
  width: 100%;
  max-width: 80rem;
  padding: 10rem 5rem;
  position: relative;

  .entry {
    clear: both;
    text-align: left;
    position: relative;

    &:not(:last-child) {
      min-height: 70px;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      left: calc(33% + 4px - 2px);
      top: 10px;
      bottom: -70px;
      width: 4px;
      background: #ddd;
    }

    .time-block {
      margin-bottom: 0.5em;
      float: left;
      width: 33%;
      padding-right: 30px;
      text-align: right;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border: 4px solid $color-primary;
        background-color: #fff;
        border-radius: 100%;
        top: 15%;
        right: -8px;
        z-index: 99;
      }

      p {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    .big-circle {
      &::before {
        width: 16px !important;
        height: 16px !important;
        right: -12px !important;
      }

      p {
        font-size: 2.8rem !important;
        font-weight: 700;
      }
    }

    .title-block {
      margin: 0 0 3em;
      float: right;
      width: 66%;
      padding-left: 3rem;

      a {
        font-size: 1.6rem;
        color: rgb(33, 37, 41);

        &:hover {
          color: #0a58ca;
        }
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 4px solid $color-primary;
    background-color: #fff;
    border-radius: 100%;
    bottom: 90px;
    left: calc(33% + 20.5px);
    transform: translateX(-50%);
    z-index: 99;
  }
}
