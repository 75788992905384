@import "../../assets/scss/variable.scss";

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    width: 100%;
  }

  .picture-block {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;

    h1,
    h3 {
      font-size: 8rem;
    }
  }

  .text-box {
    margin-top: -3rem;

    a {
      color: #fff !important;
      padding: 1.3rem 2.5rem;
      background: $color-primary;
      border-radius: 20px;
      margin: 2rem 0;
      display: inline-block;
      font-weight: 700;
      font-size: 1.5rem;
    }
  }
}
