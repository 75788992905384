main {
  padding: 5rem 0;

  .article-block {
    margin-bottom: 12rem;

    .article-head-block {
      margin-bottom: 5rem;

      .author-info {
        margin-bottom: 0.5rem;
      }
    }
  }
}
