@import "/src/assets/scss/variable.scss";

.heart-btn {
  display: block;
  position: absolute;
  transform: translate(0, -50%);
  margin: 30px 0 10px 0;
}
.content {
  padding: 8px 10px;
  display: flex;
  border: 2px solid #eae2e1;
  border-radius: 5px;
  cursor: pointer;
}
.content.heart-active {
  border-color: #f9b9c4;
  background: #fbd0d8;
}
.heart {
  position: absolute;
  background: url(#{$image-path} + "/other/heart.png") no-repeat;
  background-position: left;
  background-size: 2900%;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 28%;
  transform: translate(-65%, -50%);
}
.text {
  font-size: 14px;
  margin-left: 20px;
  color: grey;
  font-family: "Montserrat", sans-serif;
}

.heart-num {
  color: grey;
}

.heart-num:before {
  content: "";
  font-size: 14px;
  margin-left: 7px;
  font-weight: 600;
  color: #9c9496;
  font-family: sans-serif;
}
.heart-num.heart-active:before {
  content: "";
  color: #000;
}
.text.heart-active {
  color: #000;
}
.heart.heart-active {
  animation: animate 0.8s steps(28) 1;
  background-position: right;
}
@keyframes animate {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
