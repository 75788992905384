@import "../../assets/scss/variable.scss";

.pagination {
  margin-top: 8rem;

  .arrow-icon {
    color: black;
    font-size: 1.5rem;
  }

  li {
    display: inline-block;
    margin: 0 0.5rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: 4.5rem;
      padding: 0;
      line-height: 1;
      border: 1px solid #dee2e6;
      border-radius: 50%;

      &:hover {
        background-color: #f8f9fa;
      }
    }
  }

  .active {
    a {
      color: #fff;
      background-color: #4ba1fa;
      border-color: #4ba1fa;

      &:hover {
        color: #fff;
        background-color: #4ba1fa;
        border-color: #4ba1fa;
      }
    }
  }
}
