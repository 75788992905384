html {
  font-size: 62.5%;
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}

h5 {
  line-height: 1.2;
  font-size: 2rem;
}

a {
  color: #687385;
  text-decoration: none;
}

img,
image,
video {
  max-width: 100%;
}

.has-dot {
  position: relative;
  padding-left: 15px;
}
.has-dot::before {
  content: "";
  width: 3px;
  height: 3px;
  background: #999;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: block;
  left: 6px;
}

.img-hover-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 280px;
}

.transition-normal {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1); /* 貝茲曲線函數 */
}

.entry-meta {
  line-height: 1;
  color: #666;
}

.img-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

li {
  list-style: none;
}

.text-danger {
  color: #f60505 !important;
}

//========================

$article-color: #242424;

body {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
}

/*Search form*/
// .main-search-form {
//   transform: scaleY(0);
//   height: 0px;
//   width: 100%;
// }
// .open-search-form .main-search-form {
//   overflow: hidden; /*  隱藏超出元素框的內容 */
//   transition: transform 0.2s ease-in-out; /*開始結束慢，中間快*/
//   height: 100vh;
//   transform: scaleY(1);
//   transform-origin: top; /*  將變形的基準點設置為元素的頂部 */
//   position: absolute;
//   background: #fff;
//   z-index: 14;
// }
// .search-text-bg {
//   font-size: 80px;
//   text-transform: uppercase;
//   opacity: 0.1;
// }
// .main-search-form-cover {
//   max-width: 800px;
// }
// .search-header {
//   border-bottom-width: 3px;
//   border-bottom-style: solid;
//   border-bottom-color: var(--color-muted);
//   font-style: italic;
// }
// .search-header .form-control {
//   border-width: 0;
// }
// .search-header input[type="text"] {
//   font-size: 18px;
// }
