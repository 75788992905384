//========== padding ==========

.pb-50 {
  padding-bottom: 50px !important;
}

//========== margin ==========

.mt--50 {
  margin-top: -5rem !important;
}

.mt-30 {
  margin-top: 3rem !important;
}

.mt-50 {
  margin-top: 5rem !important;
}

.mb--35 {
  margin-bottom: -3.5rem !important;
}
