$color-green: #15cd72;
$color-yellow: #f0ad4e;
$color-red: #d9534f;

.notice-box {
  width: 280px;
  padding: 25px 15px;
  background: #fff;
  cursor: pointer;
  border: 2px solid currentColor;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.12) 1px 3px;

  position: fixed;
  right: 20px;
  top: 80px;

  &.notice-success {
    color: $color-green;
  }

  &.notice-warning {
    color: $color-yellow;
  }

  &.notice-error {
    color: $color-red;
  }

  p {
    margin-bottom: 0;
  }
}
