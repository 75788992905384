@import "../../assets/scss/variable.scss";

.about-container {
  display: flex;
  justify-content: center;
  padding: 8rem;
  min-height: 100vh;

  .profile-section {
    width: 30rem;
    min-width: 19rem;
    height: 45rem;
    background-color: $color-primary;
    padding: 3rem;
    border-radius: 15px;
    text-align: center;

    .profile-image {
      object-fit: cover;
      border-radius: 50%;
      border: 3px solid white;
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
    }

    .profile-description {
      font-size: 1.6rem;
      color: white;
    }

    .social-links {
      justify-content: center;
      font-size: 3rem;
      margin-top: 5rem;

      a {
        color: white;

        &:not(:last-child) {
          color: white;
          margin-right: 2rem;
        }
      }
    }
  }

  .about-me-section {
    max-width: 55rem;

    .about-me-block {
      padding: 0 6rem 3rem 6rem;

      h2 {
        font-size: 3.2rem;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2;
      }
    }
  }
}
