@import "../../assets/scss/variable.scss";

html,
body {
  height: 100%;
}

#root {
  display: flex; // 使用 flexbox 布局
  flex-direction: column; // 以列的方式排列子元素
  min-height: 100%; // 最小高度为 100% 以撑开整个浏览器窗口
}

footer {
  padding-bottom: 2rem;
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-grey;

  p {
    font-size: 2rem;
  }
}
