@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
