@import "../../assets/scss/variable.scss";

main {
  .feature {
    padding: 6rem 0;
    background: $color-grey;

    h2 {
      font-size: 5rem;
      font-weight: 900;
      span {
        color: $color-primary;
      }
    }

    h3 {
      font-size: 4.2rem;
      font-weight: 900;
    }
  }

  .hot-tag-container {
    padding: 30px 0;
    align-self: center;
    font-size: 1.2rem;

    .hot-tag-icon {
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .hot-tag-block {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0;

      i {
        margin-right: 0.5rem;
        color: $color-muted;
      }
    }

    .tags {
      letter-spacing: 0.5px;

      .tag {
        border: 1px solid;
        display: inline-block;
        margin-bottom: 5px;
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }

    .list-inline-item:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .article-pagnation-container {
    margin-bottom: 3rem;

    .hover-up:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.2), 0 0 0 transparent;
    }

    .main-article {
      margin-bottom: 3rem;
      transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
      border-radius: 10px;

      .img {
        min-height: 420px;
        background-image: url("../../assets/imgs/article/other/1/other1.png");
      }

      .content-block {
        position: absolute;
        bottom: 0;
        margin: 0 3rem;
        padding-bottom: 3rem;
        color: $color-white;
      }

      .post-title {
        margin-bottom: 2rem;
        font-weight: 900;
        color: $color-white;
      }

      .main-article-info {
        font-size: 1.2rem;
        color: $color-white;
        margin-top: 1rem;
        padding: 0 0.5rem;
      }

      .img-overlay {
        max-height: 150px;
      }
    }

    .tags {
      margin-bottom: 2rem;
      font-size: 1.2rem;

      .tag {
        border: 1px solid;
        display: inline-block;
        margin-bottom: 5px;
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .java-tag-color {
        color: #28a745;
      }

      .network-tag-color {
        color: #ffc107;
      }

      .reading-tag-color {
        color: #007bff;
      }

      .database-tag-color {
        color: #17a2b8;
      }

      .security-tag-color {
        color: #dc3545;
      }

      .other-tag-color {
        color: #6c757d;
      }
    }

    .img-overlay {
      min-height: 220px;
      border-radius: 10px 10px 0 0;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
          to bottom,
          /* 起始色彩 起始色彩位置 */ transparent 50%,
          /* 結束色彩 結束色彩位置 */ rgba(0, 0, 0, 0.8) 100%
        );
      }
    }

    article {
      margin-bottom: 3rem;

      .post-card {
        border: 1px solid #f0f8ff;
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
        position: relative;
        border-radius: 10px;

        .post-content {
          background: #fff;
          border-radius: 0 0 10px 10px;
          padding: 3rem;

          .tags {
            margin-bottom: 1rem;
            line-height: 1;
          }

          .post-card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            min-height: 115px;
            .post-title {
              font-size: 2rem;
              font-weight: 600;
            }

            .post-date-read-time {
              font-size: 1.3rem;
              line-height: 1;
              text-transform: uppercase;
              color: #666;

              .has-dot::before {
                left: 6px;
              }
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          inset: 10px 10px 0 10px;
          border-radius: 10px;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08), 0 0 0 transparent;
          transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
          z-index: -1;
        }
      }
    }
  }
}

// TODO
.list-style-2 .post-thumb .img-hover-slide {
  min-height: 150px;
}
