.preloader {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 999999;
  transition: 0.6s;
}

.circle {
  margin: 40px auto;
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #2d3d8b;
  box-shadow: -20px 0px 0px #2d3d8b;
  border-radius: 50%;
  animation: circle_classic 1s ease-in-out infinite alternate;
}

@keyframes circle_classic {
  0% {
    /*動畫起始點*/
    opacity: 0.1;
    transform: rotate(0deg) scale(0.5); /*旋轉0度，縮放0.5倍大小*/
  }
  100% {
    /*動畫結束點*/
    opacity: 1;
    transform: rotate(360deg) scale(1.2); /*旋轉360度，縮放1.2倍大小*/
  }
}
